"use client"

import Image from 'next/image';
import Drawer from '@apg.gg/core/lib/Drawer';
import Button from '@apg.gg/core/lib/Button';
import { EditProfileModalProps } from '@/domain/account/edit-profile-modal.interface';
import { lazy, useEffect, useState } from 'react';
import delay from '@apg.gg/core/lib/delay';
import { UserPartner } from '@/domain/account/partners.interface';
import Select from '@apg.gg/core/lib/Select';
import { HardwaresEndpoints } from '@/graph/profile-hardwarers.endpoints';
import { Hardware, UserHardware } from '@/domain/account/setup.interface';
import { useQueryClient } from '@tanstack/react-query';
import { PaginationResponse } from '@/domain/general.interface';
import { toast } from 'react-toastify';
import { RequestError } from '@/graph/general.endpoints';
import ArrowLeftIcon from '@apg.gg/icons/lib/ArrowLeftIcon';
import PlusIcon from '@apg.gg/icons/lib/PlusIcon';
import { useTranslations } from 'next-intl';

const SetupAddHardwareModal = lazy(() => import('./SetupAddHardwareModal'));
interface AddHardwareProps {
  isOpen: boolean;
  timestamp: number;
  userHardware?: any;
}

export const HardwareTemplate = ({ hardware }: { hardware?: Hardware }) => {
  return (
    <div className="flex items-center gap-4">
      <Image 
        src={hardware?.image || ''}
        alt={hardware?.name || ''}
        className="h-12 w-12 object-contain object-center rounded-xl bg-white"
        width={48}
        height={48}
      />
      <div className="flex flex-col">
        <h3 className="text-white text-title-sm">{hardware?.name || ''}</h3>
        <span className="text-blue-300 text-caption">{hardware?.hardwareType.name || ''}</span>
      </div>
    </div>
  )
}

const SetupModal: React.FC<EditProfileModalProps<UserPartner>> = ({ 
  userId,
  token,
  isOpen, 
  onClose, 
  onSuccess, 
  onError
}) => {
  const t = useTranslations('Profile');
  const [showAddHardware, setShowAddHardware] = useState<AddHardwareProps>({
    isOpen: false,
    timestamp: Date.now()
  });

  const queryClient = useQueryClient();
  const { items } = queryClient.getQueryData(['apg-user-setup', userId]) as PaginationResponse<UserHardware>;

  const [showDrawer, setShowDrawer] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      delay(() => setShowDrawer(true));
    }
  }, [isOpen])

  const handleOnClose = () => {
    setShowDrawer(false);
    delay(() => onClose());
  }

  const handleSelectHardware = async (hardwareId: string) => {
    if (items?.find((item: UserHardware) => item.hardware.id === Number(hardwareId))) {
      toast.warning(`${t('setup.error-hardware-already-added')}`);
      return;
    }

    try {
      const response = await HardwaresEndpoints.addHardware({
        data: {
          hardwareId: Number(hardwareId),
          isActive: true,
        },
        token
      });

      if (response) {
        toast.success(`${t('setup.label-success-hardware-added')}`);
        onSuccess();
        handleOnClose();
      }
    } catch (error) {
      onError(error as RequestError);
    }
  }

  return (
    <Drawer
      edgeToEdge={true}
      position='left'
      isOpen={showDrawer}
      className="z-[90]"
      onClose={handleOnClose}
      disableClickOutsideToClose={false}
      content={
        <div className="flex flex-col gap-4 w-full">
          <div className="flex gradient-3 h-28 gap-4 px-4 sm:px-16 items-center">
            <Button onClick={handleOnClose} icon={<ArrowLeftIcon />} className="!bg-black hover:!bg-black-800 w-12 h-12" iconSize='xl'></Button>
            <div className="flex flex-col">
              <h1 className="text-white text-headline-md sm:text-headline-lg uppercase">{t('section-setup-title')}</h1>
              <span className="gradient-1 h-2 w-24 rounded-2xl"></span>
            </div>
          </div>
          <div className="scrollbar-small-content flex flex-col px-2 sm:px-36 py-4 sm:py-8 gap-8 w-full max-w-6xl mx-auto">
            <div className="flex flex-col w-full p-4 sm:p-8 bg-black-800 rounded-2xl gap-4">
              <h3 className="uppercase text-title-lg">{t('section-setup-headline')}</h3>
              <div className="flex flex-col gap-6 h-96 overflow-y-scroll pt-4 pr-2">
                <Select
                  placeholder={t('setup.placeholder-select-setup-equipment')}
                  options={[]}
                  showSearchIcon={true}
                  onSelect={value => handleSelectHardware(value as string)}
                  isSearchable={true}
                  searchExternal={true}
                  onSearch={async(query: string) => {
                    const response = await HardwaresEndpoints.getHardwaresSearch(query);
                    return response;
                  }}
                />
                <div className="flex items-center gap-5">
                  <Button
                    type="primary"
                    icon={<PlusIcon />}
                    iconSize='2xl'
                    onClick={() => setShowAddHardware({
                      isOpen: true,
                      timestamp: Date.now(),
                      userHardware: {}
                    })}
                  ></Button>
                  <span
                    role='button' 
                    className="text-title-sm cursor-pointer"
                    onClick={() => setShowAddHardware({
                      isOpen: true,
                      timestamp: Date.now(),
                      userHardware: {}
                    })}
                  >
                    {t('setup.label-cant-find-item')}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Add hardware drawer */}
          <SetupAddHardwareModal 
            key={showAddHardware.timestamp}
            isOpen={showAddHardware.isOpen}
            t={t}
            userId={0}
            onClose={() => setShowAddHardware({
              isOpen: false,
              timestamp: Date.now()
            })}
            onSuccess={() => {
              setShowAddHardware({
                isOpen: false,
                timestamp: Date.now()
              })

              onSuccess();
            }}
            onError={onError}
            token={token}
            data={showAddHardware.userHardware || {} as any}
          />
        </div>
      }
    />
  );
};

export default SetupModal;
